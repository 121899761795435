.wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    overflow-x: hidden;
    position: relative;
}

.map {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 95%;
}

h1 {
    font-size: 3rem;
    font-weight: 700;
}

h2 {
    font-size: 1.5rem;
    font-weight: 600;
}

.containerProps {
    height: "100vh"
}


@media screen and ( max-width: 767px) {

    .wrapper {
        flex-direction: column;
        /* height: auto; */
    }

    .containerProps {
        height: "50vh"
    }
    
    h1 {
        font-size: 1.5rem;
        line-height: 1.5rem;
    }
    
    h2 {
        font-size: 1rem;
    }
}

.map_tooltip {
    background-color: red;
    fill: red !important;
    stroke: red !important;
    display: block;
}

path[fill="brown"] {
    fill: brown !important;
    /* stroke: green !important; */
}

tspan {
    /* fill: red !important;  */
    width: 1rem !important;
    height: 1rem !important;
    background-color: red !important;
    display: block;
}


.activeDataLabel {

}

.dataLabel {
    background-color: red;
}