.lineBlock {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0rem 3rem 0;
    max-width: 100%;
}

.bigLine {
    width: calc(100vw - 190px - 3rem);
    height: 3px;
    background-color: rgba(0, 170, 232);
    display: block;
}

.smallLine {
    width: calc(100vw - 190px - 3rem);
    height: 1px;
    background-color: rgba(0, 170, 232);
    display: block;
    margin-top: 2px;
}

.heroWord {
    color: rgb(175, 0, 23);
    font-size: 1.5rem;
    margin-left: 1.5rem;
    font-weight: 600;
    position: relative;
}

.model2 {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 29% 100%, 0 66%);
    position: absolute;
    right: calc(-3rem - 2px);
    background-color: rgb(175, 0, 23);
    width: 40px;
    height: 40px;
}

@media screen and (max-width: 768px) {
    .lineBlock {
        margin-left: 1rem;
    }

    .bigLine {
        width: calc(100vw - 140px - 3rem);
    }

    .smallLine {
        width: calc(100vw - 140px - 3rem);
    }

    .heroWord {
        font-size: 1.5rem;
    }

    .model2 {
        right: calc(-5rem - 2px);
    }
}