.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 3rem;
}

.icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 3rem;
}

.min_logo {
    margin-right: 2rem;
}

.min_logo img {
    height: 80px;
}

.youn_logo img {
    height: 80px;
}

.titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mainTitle {
    color: rgba(0, 170, 232);
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-left: 1.5rem;
}

.sideTitle {
    color: #fff;
    background-color: rgb(175, 0, 23);
    padding: 1rem 1.5rem;
    margin: 0 0 0 3rem;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: 0.3rem;
    line-height: 25px;
}


@media screen and (max-width: 768px) {
    .main {
        margin-top: 2rem;
    }

    .min_logo img {
        height: 40px;
        margin: 0.25rem;
    }

    .youn_logo img {
        height: 40px;
        margin: 0.25rem;
    }
    .icons {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        flex-wrap: wrap;
        margin: 0;
    }

    .min_logo {
        margin-right: 0;
    }

    .mainTitle {
        font-size: 1.2rem;
        line-height: 1.2rem;
        margin-left: 0.5rem;
    }

    .sideTitle {
        margin: 0 0 0 1rem;
        font-weight: 600;
        padding: 0.5rem;
        font-size: 0.7rem;
        letter-spacing: 0.15rem;
        line-height: 1rem;
    }
}
