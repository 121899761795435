.main {
    width: 100%;
    max-width: 1400px;
    background-image: url('../../img/bg.png');
    background-size: cover;
    position: relative;
    background-color: grey;
    margin-top: 4rem;
    overflow-x: hidden;
    overflow-y: auto;
}

.model_line1 {
    clip-path: polygon(68% 0, 81% 0, 13% 100%, 0% 100%);
    position: absolute;
    top: 0;
    left: 1rem;
    height: 5rem;
    width: 8rem;
    z-index: 999;
    background-color: red;
}

.model_line2 {
    clip-path: polygon(68% 0, 81% 0, 13% 100%, 0% 100%);
    position: absolute;
    top: -1rem;
    left: 4rem;
    height: 5rem;
    width: 8rem;
    z-index: 999;
    background-color: red;
}

.model_line3 {
    clip-path: polygon(100% 0, 100% 16%, 14% 100%, 0 100%);
    position: absolute;
    bottom: 9px;
    right: -3rem;
    height: 5rem;
    width: 8rem;
    z-index: 999;
    background-color: red;
}

.model_line4 {
    clip-path: polygon(100% 23%, 100% 38%, 38% 100%, 24% 100%);
    position: absolute;
    bottom: 0.5rem;
    right: -3rem;
    height: 5rem;
    width: 8rem;
    z-index: 999;
    background-color: red;
}

.heroBlock {
    margin: 1rem 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.heroTextBlock {
    margin-right: 3rem;
    display: table;
    height: 100%;
    min-height: fit-content;
    overflow-wrap: anywhere;
}

.heroText1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    text-align: right;
    overflow-wrap: anywhere;
}

.heroText2 {
    font-size: 1.2rem;
    text-align: right;
    overflow-wrap: anywhere;
}

.heroName {
    color: #fff;
    font-size: 1.2rem;
    font-weight: 600;
    width: fit-content;
    max-width: 400px;
    background-color: rgba(175, 0, 23, .9);
    text-align: center;
    padding: 1rem 5rem;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    clip-path: polygon(100% 0, 100% 83%, 91% 100%, 0 100%, 0 0);
    text-transform: uppercase;
}

.content::-webkit-scrollbar, .content div::-webkit-scrollbar {
    background-color: rgba(175, 0, 23, 0.162);
    width: .4rem;
    height: .4rem;
  }
  
  .content::-webkit-scrollbar-thumb, .content div::-webkit-scrollbar-thumb {
    background-color: rgba(175, 0, 23, .9);
  }

.dopInfo {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: auto 0 1rem 3rem;
}


.qrcode {
    margin-top: 1rem;
    width: 150px;
    height: 150px;
    float: left;
    clip-path: polygon(11% 0, 100% 0, 100% 100%, 0 100%, 0 13%);
}

.qrcode img {
    height: 150px;
}

.tagList {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 80%;
}

.tagItemBlue {
    color: rgba(0, 170, 232);
    font-size: 1rem;
    font-weight: 700;
    padding: .2rem;
    margin-right: .5rem;
}

.tagItemRed {
    color: rgb(175, 0, 23);
    font-size: 1rem;
    font-weight: 700;
    padding: .2rem;
    margin-right: .5rem;
}

.back {
    position: absolute;
    right: 0rem;
    top: 0rem;
    text-decoration: none;
    color: #fff;
    background-color: rgb(175, 0, 23);
    display: block;
    font-size: 2rem;
    padding: .5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.tagListMob {
    display: none;
}

@media screen and ( max-width: 767px) {
    
    .popupInner {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        display: block;
    }
}

@media screen and (max-width: 768px) {

    .model_line1 {
        top: -25px;
    }
    
    .model_line2 {
        top: -40px;
    }

    .heroName {
        font-size: .8rem;
        width: 100%;
        padding: .5rem;
        margin-top: 1rem;    
    }
    
    .dopInfo {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 2rem 0 0 0rem;
    }
    .qrcode {
        margin: 0 1rem 0 auto;
        width: 100px;
        height: 100px;
        float: right !important;
        clip-path: polygon(11% 0, 100% 0, 100% 100%, 0 100%, 0 13%);
    }

    .qrcode img {
        height: 100px;
    }

    .tagListDeck {
        display: none;
    }

    .tagListMob {
        display: flex;
        padding: 1rem 0 0 1rem;
    }
    .tagItemRed, .tagItemBlue {
        font-size: .8rem;
    }
    .heroTextBlock {
        margin-right: 0rem;
        height: 100%;
        min-height: 390px;
    }

    .model_line3 {
        display: none;
    }

    .model_line4 {
        display: none;
    }
}

.popup {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .8);
    position: absolute;
    z-index: 9999;
}

.popupInner {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
}