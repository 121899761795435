.title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0 0 1.5rem;
}

.selectBlock {
    display: flex;
    align-items: center;
    z-index: 999;
    width: fit-content;
    margin: 0 auto;
}

.buttonCountry {
    font-size: 1rem;
    padding: .5rem;
    border: 3px solid #af0017;
    border-radius: .2rem;
    background-color: #af0017;
    color: #fff;
    font-weight: 600;
    outline: none;
    cursor: pointer;
    margin: 0 1rem;
}