.sideBar {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    height: 100vh;
    background-image: url('../../img/bg_menu.png');
    background-size: cover;
    position: relative;
    width: 200px;
    display: flex;
    flex-direction: column;
}

.monument {
    width: 200px;
    height: 200px;
}

.textBlock {
    z-index: 0;
    color: #fff;
    margin: -115px 1rem 0;
}
@media screen and ( max-width: 767px) {
    .sideBar {
        justify-content: flex-start;
        align-items: flex-start;
        width: 100%;
        height: fit-content;
        flex-direction: row;
    }

    .monument {
        width: 150px;
        height: 150px;
    }

    .textBlock {
        margin: 0 1rem;
    }
}
