@import-normalize;
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;600;800&display=swap');

* {
    box-sizing: border-box;
}

.ql-toolbar.ql-snow{
  display: none;
  visibility: hidden;
  width: 0;
  height: 0;
  border: 0;
}

.ql-container.ql-snow {
  border: 0px solid transparent !important;
}

.ql-editor {
  max-height: 330px;
}

.ql-editor > *{
  text-align: right;
  font-size: 15px;
}

.highcharts-point {
  opacity: 1 !important;
}