.withOutPhoto {
    margin-left: 3rem;
    width: 200px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: gray;
    text-align: center;
    padding: 1rem;
    color: aliceblue;
    font-size: 1.5rem;
}

.activeHeroPhoto {
    margin-left: 3rem;
    height: 210px;
    width: 265px;
    object-fit: contain;
    user-select: none;
}

.heroPhoto {
    display: none;
}

.slider {
    position: relative;
    flex-direction: column;
    display: flex;
    justify-content: center;

}

.arrowLeft {
    position: absolute;
    left: 25px;
    top: 40%;
    cursor: pointer;
    background-color: rgba(255,255,255,.8);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    z-index: 1;
}

.arrowRight {
    position: absolute;
    right: -25px;
    top: 40%;
    cursor: pointer;
    background-color: rgba(255,255,255,.8);
    width: 50px;
    height: 50px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    z-index: 1;
}

.points {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20px;
    /* background-color: red   ; */
}

.point {
    width: 10px;
    height: 10px;
    background-color: rgba(255,255,255,.5);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

.activePoint {
    width: 10px;
    height: 10px;
    background-color: rgba(255,255,255,1);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .activeHeroPhoto {
        margin-left: 1rem;
        width: 120px;
        object-fit: contain;
    }

    .withOutPhoto {
        margin-left: 1rem;
        width: 120px;
        height: 200px;
    }
}