.popupListWrap {
    z-index: 1;
    background-color: rgba(0, 0, 0, .3);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
}

.popupList {
    padding: .5rem .8rem;
    border: 3px solid #af0017;
    border-radius: .2rem;
    background-color: #faeadb;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-height: 50vh;
    overflow-y: auto;
}

.popupList span {
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    padding: .2rem 0;
    transition: color .2s ease-in-out;
}

.popupList span:hover {
    color: red;
}

.list { 
    display: flex; 
    flex-direction: column; 
    max-height: 290px;
}

.list bold {
    display: inline-block;
    font-weight: 600;
    margin-left: .5rem;
    margin-bottom: .5rem;
    color: #af0017;
}

.title {
    margin-top: .5rem;
}