.search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 3rem 0 3rem;
}

.searchTitle {
    color: rgba(0, 170, 232);
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
    display: block;
    position: relative;
}

.model1 {
    clip-path: polygon(100% 0, 100% 67%, 82% 100%, 0 99%, 0 0);
    position: absolute;
    left: -3rem;
    background-color: rgba(0, 170, 232);
    width: 40px;
    height: 40px;
}

.searchName {
    color: #000;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 0.2rem;
}

.searchName b {
    font-size: 1rem;
}

.searchRegion {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    text-align: right;
}

.flag {
    margin-left: 1rem;
    display: block;
    max-width: 139px;
    min-width: 105px;
    width: 100%;
    height: 70px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: left;    
}

@media screen and (max-width: 768px) {
    .search {
        margin: 1rem 1rem 0 1rem;
        align-items: flex-start;
    }
    .searchName {
        font-size: 1rem;
    }
    .flag {
        margin-left: .5rem;
        max-width: 120px;
        min-width: 100px;
        width: 100%;
        height: 70px;
    }
}