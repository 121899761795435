.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;    
}
.loader {
    width: 140px;
    height: 140px;
    display: block;
    margin: 0 auto;
    box-sizing: border-box;
    position: absolute;
}
.loader::after {
    content: '';
    box-sizing: border-box;
    width: 140px;
    height: 140px;
    left: 0;
    bottom: 0;
    position: absolute;
    border-radius: 50% 50% 0;
    border: 30px solid #af0017;
    transform: rotate(45deg) translate(0, 0);
    box-sizing: border-box;
    animation: animMarker 0.4s ease-in-out infinite alternate;
}
.loader::before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 150%;
    width: 100px;
    height: 15px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.2);
    animation: animShadow 0.4s ease-in-out infinite alternate;
}
@keyframes animMarker {
    0% {
        transform: rotate(45deg) translate(5px, 5px);
    }
    100% {
        transform: rotate(45deg) translate(-5px, -5px);
    }
}
@keyframes animShadow {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

/* @media screen and (max-width: 768px) {
    .loader {
        width: 140px;
        height: 140px;
    }
    .loader::after {
        width: 140px;
        height: 140px;
        border: 30px solid #af0017;
    }
    .loader::before {
        width: 100px;
        height: 15px;
    }

    .container {
        position: absolute;
        top: 300px;
        z-index: 10000;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;    
    }
} */